import Task, { FocusedTask, TaskSearchParams } from "../types/Task"
import { HOST } from "../api"
import { store } from "../redux"
import { startLoadFocusedTasksAction, startSaveFocusedTaskAction, startJustTodayTasksAction, startLoadDailyTasksAction, successJustTodayTasksAction, successLoadDailyTasksAction, successSaveTaskAction, successSaveFocusedTaskAction, startSaveTaskAction, successLoadFocusedTaskTasksAction, startUpdateStateChangeAction, successUpdateStateChangeAction, startMoveTaskToSaveForLaterAction, successMoveTaskToSaveForLaterAction, startMoveTasksToJustTodayAction, startDeleteTaskAction, successDeleteTaskAction, successLoadSaveForlaterTasksAction, startLoadSaveForlaterTasksAction, setCheckInStateAction, startLoadJustYesterdayTasksAction, successLoadJustYesterdayTasksAction, startLoadSearchResultTasksAction, successLoadSearchResultTasksAction, resetSearchResultTaskAction, successMoveTasksToJustTodayAction, startMoveTasksToSaveForLaterAction, successMoveTasksToSaveForLaterAction, deleteTaskFromSearchResultsAction } from "../redux/tasks/tasksActions"
import { parseCurrentDateTimeIso, parseYesterdayDateTimeIso } from "../utils/date"
import { normalizeActiveTasks } from "../utils/tasks"
import { errorToast } from "../utils/toast"
import { openDatabase, EMPTY_DB, simpleTransaction } from "./localDBService"
import * as Random from 'expo-random';
import { v4 as uuid } from 'uuid';
import { DateTime } from "luxon"
import { WebSQLDatabase } from "expo-sqlite"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { DesktopToken, localStore } from "./desktopStoreService"
import { Platform } from "react-native"
import { toQueryString } from "../utils/queryString"
import { Body } from "@tauri-apps/api/http"
import { fetchConfig } from "../utils/headers"

// @ts-ignore
export let db = EMPTY_DB as WebSQLDatabase
openDatabase().then(_db => {
  db = _db as WebSQLDatabase
}).catch(e => {
  errorToast(e)
})


const loadDailyTasksLocal = async (date: string) => {
  const tasks = await simpleTransaction(db, "select * from (SELECT t.*, ct.id as checked_id, checked FROM tasks t LEFT JOIN checked_tasks ct ON ct.task_id = t.id and ct.\"date\" = ?) ttt where ttt.task_type = 'DAILY_ROUTINE'", [date])
  let parsedTasks = []
  const now = DateTime.now().toMillis()
  for (let t of tasks) {
    if (!t.checked_id) {
      let check_task_id = uuid()
      await simpleTransaction(db, "INSERT INTO checked_tasks(id, task_id, created_at, updated_at, checked, date) VALUES(?, ?, ?, ?, ?, ?)", [check_task_id, t.id, now, now, 0, date])
      parsedTasks.push({
        ...t,
        // general conversions
        active: t.active === 1,
        type: t.task_type,
        // checked_tasks changes
        checked_id: check_task_id,
        checked: false,
        date
      })
    } else {
      parsedTasks.push({ ...t, active: t.active === 1, checked: t.checked === 1, type: t.task_type })
    }
  }
  return parsedTasks;
}
const loadJustTodayTasksLocal = async (date: string) => {
  const tasks = await simpleTransaction(db, "select * from (SELECT t.*, ct.id as checked_id, checked FROM tasks t LEFT JOIN checked_tasks ct ON ct.task_id = t.id) ttt where ttt.task_type = 'JUST_TODAY' and ttt.date = ?", [date])
  return tasks.map((t: any) => ({ ...t, active: t.active === 1, checked: t.checked === 1, type: t.task_type }))
}
const loadSaveForLaterTasksLocal = async () => {
  const tasks = await simpleTransaction(db, "select * from (SELECT t.*, ct.id as checked_id, checked FROM tasks t LEFT JOIN checked_tasks ct ON ct.task_id = t.id) ttt where ttt.task_type = 'SAVE_FOR_LATER'", [])
  return tasks.map((t: any) => ({ ...t, active: t.active === 1, checked: t.checked === 1, type: t.task_type }))
}

export async function loadDailyTasks(disallowSort: boolean) {
  try {
    const { token, mode } = store.getState().user

    store.dispatch(startLoadDailyTasksAction())

    let tasks = [];
    if (mode === 'local') {
      tasks = await loadDailyTasksLocal(parseCurrentDateTimeIso())
    } else {
      tasks = await fetch(`${HOST}/tasks?filter=DAILY_ROUTINE&date=${parseCurrentDateTimeIso()}`, {
        ...fetchConfig()
      }).then(res => res.json())
    }

    store.dispatch(successLoadDailyTasksAction(normalizeActiveTasks(tasks), disallowSort))

  } catch (e) {
    console.log(e)
    // errorToast(e, 'loadDailyTasks');
  }
}

export async function loadJustTodayTasks(disallowSort: boolean = false) {
  try {
    const { token, mode } = store.getState().user

    store.dispatch(startJustTodayTasksAction())
    let tasks = []
    if (mode === 'local') {
      tasks = await loadJustTodayTasksLocal(parseCurrentDateTimeIso())
    }
    else {
      tasks = await fetch(`${HOST}/tasks?filter=JUST_TODAY&date=${parseCurrentDateTimeIso()}`, {
        ...fetchConfig()
      }).then(res => res.json())
    }

    store.dispatch(successJustTodayTasksAction(normalizeActiveTasks(tasks), disallowSort))

  } catch (e) {
    errorToast(e, 'loadJustTodayTasks');

  }
}


const saveFocusedTaskLocal = async (taskId: string) => {
  try {
    // @ts-ignore
    if (window.__TAURI__) {
      await localStore.set('focused_task_id', { focusedTaskId: taskId });
    } else {
      if (['android', 'ios'].includes(Platform.OS)) {
        await AsyncStorage.setItem('focused_task_id', taskId)
      }
    }
  } catch (e) {
    // saving error
    console.log("writing error")
  }
  return {}
}

export const saveFocusedTask = async (itemId: string) => {
  try {
    const { token, mode } = store.getState().user
    store.dispatch(startSaveFocusedTaskAction())
    const focused_task = mode === 'local' ? (await saveFocusedTaskLocal(itemId)) : await fetch(`${HOST}/actions/focused-task`, {
      method: "POST",
      body: JSON.stringify({
        id: "",
        task_id: itemId,
        created_at: 0,
        created_by: ""
      } as FocusedTask),
      ...fetchConfig()
    }).then(res => res.json())

    store.dispatch(successSaveFocusedTaskAction())

  } catch (e) {
    errorToast(e)
  }
}

const loadFocusedTaskLocal = async () => {
  let focused_task_id = ""
  try {
    // @ts-ignore
    if (window.__TAURI__) {
      focused_task_id = (await localStore.get('focused_task_id') as any).focusedTaskId || "";
    } else {
      if (['android', 'ios'].includes(Platform.OS)) {
        focused_task_id = await AsyncStorage.getItem('focused_task_id') || ""
      }
    }
  } catch (e) {
    // saving error
    console.log("loading error")
  }

  return { task_id: focused_task_id }
}


export async function loadFocusTask() {
  try {
    const { token, mode } = store.getState().user

    store.dispatch(startLoadFocusedTasksAction())

    const focused_task = mode === 'local' ? (await loadFocusedTaskLocal()) : await fetch(`${HOST}/actions/focused-task`, {
      ...fetchConfig()
    }).then(res => res.json())

    store.dispatch(successLoadFocusedTaskTasksAction(focused_task.task_id))

  } catch (e) {

    errorToast(e, 'loadFocusTask');

  }
}

export async function loadSaveForLaterTasks() {
  try {
    const { token, mode } = store.getState().user
    if (mode === 'local') {
      store.dispatch(startLoadSaveForlaterTasksAction())
      const tasks = await loadSaveForLaterTasksLocal()
      store.dispatch(successLoadSaveForlaterTasksAction(normalizeActiveTasks(tasks)))
    } else {
      store.dispatch(startLoadSaveForlaterTasksAction())

      const tasks = await fetch(`${HOST}/tasks?filter=SAVE_FOR_LATER`, {
        ...fetchConfig()
      }).then(res => res.json())

      store.dispatch(successLoadSaveForlaterTasksAction(normalizeActiveTasks(tasks)))
    }
  } catch (e) {
    errorToast(e, 'loadSaveForLaterTasks');
  }
}

export async function resetSearchResultTask() {
  store.dispatch(resetSearchResultTaskAction())
}

export async function loadSearchResultTasks(params: TaskSearchParams) {
  try {
    const { token, mode } = store.getState().user
    if (mode === 'local') {
      store.dispatch(startLoadSearchResultTasksAction())
      const tasks = await loadSaveForLaterTasksLocal()
      store.dispatch(successLoadSearchResultTasksAction(normalizeActiveTasks(tasks)))
    } else {
      store.dispatch(startLoadSearchResultTasksAction())

      const tasks = await fetch(`${HOST}/search/tasks`, {
        method: 'POST',
        body: JSON.stringify(params),
        ...fetchConfig()
      }).then(res => res.json())

      store.dispatch(successLoadSearchResultTasksAction(normalizeActiveTasks(tasks)))
    }
  } catch (e) {
    errorToast(e, 'loadSearchResultTasks');

  }
}

export async function loadLastDateTasks() {
  try {
    const { token, mode, meta_data } = store.getState().user
    console.log(meta_data)
    const date = meta_data.last_daily_import_date === '' ? parseYesterdayDateTimeIso() : meta_data.last_daily_import_date
    // console.log("getting tasks for date", date)
    store.dispatch(startLoadJustYesterdayTasksAction())
    let tasks = []
    if (mode === 'local') {
      tasks = await loadJustTodayTasksLocal(date)
    } else {
      tasks = await fetch(`${HOST}/tasks?filter=SAVE_FOR_LATER&date=${date}`, {
        ...fetchConfig()
      }).then(res => res.json())
    }

    store.dispatch(successLoadJustYesterdayTasksAction(normalizeActiveTasks(tasks)))

  }
  catch (e) {
    errorToast(e, "loadLastDateTasks")

  }

}

export async function loadAllTasks(disallowSort: boolean = false, includeSaveForLater: boolean = false) {
  try {
    await loadDailyTasks(disallowSort)
    await loadFocusTask()
    await loadJustTodayTasks(disallowSort)
    if (includeSaveForLater) {
      await loadSaveForLaterTasks()
    }

  }
  catch (e) { }
}


export const saveTask: (task: Task) => Promise<Task> = async (task: Task) => {
  const { token, mode } = store.getState().user

  // console.log(token, mode)
  if (mode === 'local') {
    task.id = uuid()
    task.created_at = DateTime.now().toMillis()
    task.updated_at = DateTime.now().toMillis()
    task.date = parseCurrentDateTimeIso()
    const { id, title, details, type, date, active, created_at, updated_at } = task

    await simpleTransaction(db, "INSERT INTO tasks (id, title, details, task_type, date, created_at, updated_at, active) VALUES(?,?,?,?,?,?,?,?)", [id, title, details || "", type, date, created_at, updated_at, active ? 1 : 0])
    let check_task_id = uuid()
    await simpleTransaction(db, "INSERT INTO checked_tasks(id, task_id, created_at, updated_at, checked, date) VALUES(?, ?, ?, ?, ?, ?)", [check_task_id, task.id, created_at, updated_at, task.checked ? 1 : 0, date])

    return task
  } else {
    store.dispatch(startSaveTaskAction())

    const taskresp: Task = await fetch(`${HOST}/tasks`, {
      method: "POST",
      body: JSON.stringify(task),
      ...fetchConfig()
    })
      .then(res => res.json())
    store.dispatch(successSaveTaskAction(taskresp))
    return taskresp
  }

}

const checkTaskUpdateLocal = async (isChecked: boolean, taskId: string, taskCheckedId: string) => {
  const now = DateTime.now().toMillis()
  await simpleTransaction(db, "UPDATE checked_tasks SET checked = ?, updated_at = ? WHERE id = ?", [isChecked ? 1 : 0, now, taskCheckedId])
}


export const checkTaskUpdate = async (isChecked: boolean, taskId: string, taskCheckedId: string) => {
  const { token, mode } = store.getState().user
  try {
    store.dispatch(startUpdateStateChangeAction(isChecked, taskId))
    if (mode === 'local') {
      await checkTaskUpdateLocal(isChecked, taskId, taskCheckedId)
    } else {
      await fetch(`${HOST}/actions/${!isChecked ? 'un' : ''}check-task`, {
        method: "POST",
        body: JSON.stringify({ task_checked_id: taskCheckedId }),
        ...fetchConfig()
      })
        .then(res => res.json())
    }
  } catch (e) {
    console.log(e)
  }
  store.dispatch(successUpdateStateChangeAction(isChecked))

}

const getTaskByIdLocal = async (taskId: string) => {
  const tasks = await simpleTransaction(db, "SELECT * FROM (SELECT t.*, ct.id as checked_id, checked, ct.\"date\" as ct_date FROM tasks t LEFT JOIN checked_tasks ct ON ct.task_id = t.id) ttt where ttt.id = ?", [taskId])
  if (tasks.length === 0) {
    return {}
  }

  const t = tasks[0]
  return { ...t, active: t.active === 1, checked: t.checked === 1, type: t.task_type }
}

const updateTaskLocal = async (task: Task) => {
  const { id, title, details, type, date, active, created_at, updated_at } = task
  const now = DateTime.now().toMillis()
  await simpleTransaction(db, "UPDATE tasks set title = ?, details = ?, task_type = ?, date = ?, created_at = ?, updated_at = ?, active = ? where id = ?",
    [title, details || "h", type, date, created_at, now, active ? 1 : 0, id]);
  return task
}

export const moveTasksToJustToday = async (taskIds: string[]) => {

  try {
    const { token, mode } = store.getState().user
    store.dispatch(startMoveTasksToJustTodayAction(taskIds))

    let resp: any = {};
    if (mode === 'local') {
      for (let taskId of taskIds) {
        await moveTaskToJustToday(taskId)
      }
    } else {
      resp = await fetch(`${HOST}/actions/move-to-just-today`, {
        method: 'POST',
        ...fetchConfig(),
        body: JSON.stringify({
          task_ids: taskIds,
          date: parseCurrentDateTimeIso()
        })
      })
        .then(res => res.json())
    }
    store.dispatch(successMoveTasksToJustTodayAction(taskIds))
  } catch (e) {
    // console.log(e)
  }
}
export const moveTaskToJustToday = async (taskId: string) => {
  try {
    let task = await getTaskByIdLocal(taskId)
    task.type = "JUST_TODAY"
    task.date = parseCurrentDateTimeIso()
    let changedTask = await updateTaskLocal(task as Task)
  } catch (e) {
    // console.log(e)
  }
}

export const moveTasksToSaveForLater = async (taskIds: string[], removeDate: boolean = false) => {
  try {
    console.log(taskIds, removeDate)
    const { token, mode } = store.getState().user

    store.dispatch(startMoveTasksToSaveForLaterAction(taskIds))

    if (mode === 'local') {
      for (let taskId of taskIds) {
        await moveTaskToSaveForLater(taskId)
      }
    } else {
      const resp = await fetch(`${HOST}/actions/move-task-to-save-for-later`, {
        method: "POST",
        ...fetchConfig(),
        body: JSON.stringify({
          task_ids: taskIds,
          remove_date: removeDate
        })
      })
        .then(res => res.json())
    }

    store.dispatch(successMoveTasksToSaveForLaterAction(taskIds))
  } catch (e) {
    // console.log(e)
  }
}
export const moveUncheckedYesterdayTasksToSfl = async () => {
  try {
    const { token, mode } = store.getState().user
    const date = parseCurrentDateTimeIso();
    if (mode === 'local') {

    } else {
      const resp = await fetch(`${HOST}/actions/move-unchecked-tasks-to-sfl`, {
        method: "POST",
        ...fetchConfig(),
        body: JSON.stringify({
          end_date: date
        })
      })
        .then(res => res.json())
    }
  } catch (e) {
    // console.log(e)
  }
}
export const addTaskIdToRecentTasks = async (taskIds: string[]) => {
  try {
    const { token, mode } = store.getState().user


    if (mode === 'local') {
    } else {
      const resp = await fetch(`${HOST}/actions/add-recent-task-ids-action`, {
        method: "POST",
        ...fetchConfig(),
        body: JSON.stringify({
          task_ids: taskIds,
        })
      })
        .then(res => res.json())
    }

  } catch (e) {
    // console.log(e)
  }
}
export const clearRecentTasks = async () => {
  try {
    const { token, mode } = store.getState().user


    if (mode === 'local') {
    } else {
      const resp = await fetch(`${HOST}/actions/clear-recent-task-ids-action`, {
        method: "GET",
        ...fetchConfig(),
      })
        .then(res => res.json())
    }

  } catch (e) {
    // console.log(e)
  }
}

export const moveTaskToSaveForLater = async (taskId: string) => {
  try {
    const task = await getTaskByIdLocal(taskId)
    task.type = "SAVE_FOR_LATER"
    const changedTask = await updateTaskLocal(task)
  } catch (e) {
    // console.log(e)
  }
}
export const removeDateFromSFLTask = async (taskIds: string[]) => {
  const { token, mode } = store.getState().user
  try {
    let a = await fetch(`${HOST}/actions/remove-date-from-sfl-tasks`, {
      method: "POST",
      ...fetchConfig(),
      body: JSON.stringify({ task_ids: taskIds })
    })
      .then(res => res.json())
    return a
  } catch (e) {
    console.log(e)
  }
}

export const moveTaskToSaveForLaterAndReload = async (taskId: string, deleteDate: boolean = false) => {
  await moveTasksToSaveForLater([taskId], deleteDate)
  await loadAllTasks()
}

export const deleteTask = async (taskId: string, options?: { reloadSaveForLater: boolean, removeTaskFromSearchResults?: boolean }) => {

  try {
    const { token, mode } = store.getState().user

    store.dispatch(startDeleteTaskAction(taskId));
    if (options && options.removeTaskFromSearchResults) {
      store.dispatch(deleteTaskFromSearchResultsAction(taskId));
    }

    if (mode === 'local') {
      await simpleTransaction(db, "DELETE FROM tasks WHERE id = ?", [taskId])
    }
    else {
      await fetch(`${HOST}/tasks/${taskId}`, {
        method: "DELETE",
        ...fetchConfig(),
      })
        .then(res => res.json())
    }

    if (options) {
      loadAllTasks(false, options.reloadSaveForLater)
    } else {
      loadAllTasks()
    }

    store.dispatch(successDeleteTaskAction(taskId))
  } catch (e) {

  }
}
export const removeTaskFromSearchResults = async () => {

}

export const wipeLocalTasks = async () => {
  await simpleTransaction(db, "DELETE FROM tasks", [])
  await simpleTransaction(db, "DELETE FROM checked_tasks", [])
}
export const moveYesterdayTasksToSaveForLater = async () => {
  const tasks = store.getState().allTasks.JustYesterdayIncomplete
  const taskIds = Object.keys(tasks)
  if (taskIds.length > 0) {
    await moveTasksToSaveForLater(taskIds)
  }
}

export const removeDatesForAllLastDateTasks = async () => {
  const tasks = store.getState().allTasks.JustYesterdayIncomplete
  const taskIds = Object.keys(tasks)
  if (taskIds.length > 0) {
    await removeDateFromSFLTask(taskIds)
  }
}
