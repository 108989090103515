import * as React from 'react';
import { FlatList, RefreshControl, View, Dimensions, ListRenderItem, ListRenderItemInfo, ActivityIndicator, Platform } from "react-native"
import { connect } from 'react-redux';
import { store } from '../redux';
import { setFocusTaskIdTasksAction } from '../redux/tasks/tasksActions';
import { loadAllTasks, loadLastDateTasks, loadSaveForLaterTasks, saveFocusedTask } from '../services/tasksService';
import { MappedContexts } from '../types/Context';
import Task, { MappedTasks } from '../types/Task';
import TaskItemView from './TaskItemView';
import { invoke } from '@tauri-apps/api/tauri'
import { ShowLoadingForList } from '../redux/visual/visualReducer';

const { height } = Dimensions.get("screen")
const listHeight = height - 130

const ITEM_HEIGHT = 86

function keyExtractor(item: any, index: number) {
    return item.id
}

interface ItemsFlatListProps {
    adjustToScreen?: boolean,
    listType: 'Daily' | 'JustToday' | 'SaveForLater' | 'JustYesterdayIncomplete',
    navigation: any,
    extraSpaceAtEnd?: boolean,
    setTaskIds?: (taskStringId: string[]) => void,
    focusedTaskId: string,
    allTasks: { [key: string]: MappedTasks },
    userId: string,
    filter?: (task: Task) => boolean,
    contexts: MappedContexts,
    filterIndex?: string,
    disableRefresh?: boolean,
    isSearchResults?: boolean,
    paddingInCell?: boolean | number,
    marginTop?: number,
    onEmptyListRender?: any,
    didLoadTodayTasksOnce: boolean,
    showLoadingForList: ShowLoadingForList
    hideLoader?: boolean,
    priorityTasks?: string[],
    hideArrowButton?: boolean
}

const ItemsFlatList = (props: ItemsFlatListProps) => {
    const showLoading = props.showLoadingForList[props.listType]
    const { allTasks, userId, focusedTaskId, } = props
    const [refreshing, setRefreshing] = React.useState(false);
    const [selectTaskIds, setSelectedTaskIds] = React.useState<any>({});

    const contextAsArray = Object.keys(props.contexts).map((id: string) => props.contexts[id])
    const filterFunc = props.filter ? props.filter : (task: Task) => {
        if (props.filterIndex === 'all') {
            return true
        }

        if (props.filterIndex === 'contextless') {
            return !task.context_id
        }

        if (task.context_id === props.filterIndex) {
            return true
        }

        return false
    }

    const setASelectedItemId = (taskId: string) => {
        if (props.listType === 'SaveForLater' || props.listType === 'JustYesterdayIncomplete') {
            const selectTaskIdsCopy = { ...selectTaskIds }
            if (selectTaskIdsCopy[taskId]) {
                selectTaskIdsCopy[taskId] = false
            } else {
                selectTaskIdsCopy[taskId] = true
            }
            if (props.setTaskIds) {
                props.setTaskIds(Object.keys(selectTaskIdsCopy).filter((taskId: string) => selectTaskIdsCopy[taskId]))
            }
            setSelectedTaskIds(selectTaskIdsCopy)
        } else {
            store.dispatch(setFocusTaskIdTasksAction(taskId))
            saveFocusedTask(taskId)
            // @ts-ignore
            if (window.__TAURI__) {
                invoke('set_current_focused_task', {
                    task: taskId ? allTasks[props.listType][taskId] : null,
                })
                    .then((res) =>
                        console.log(res)
                    )
                    .catch((e) => console.error(e))
            }
        }
    }

    let tasks: MappedTasks = allTasks[props.isSearchResults ? 'SearchResults' : props.listType];
    let tasksIds = Object.keys(tasks)

    if (props.listType === 'SaveForLater' && (props.priorityTasks || []).length > 0) {
        const tasksIdsWithoutPriorityTasks = tasksIds.filter((tId: string) => !(props.priorityTasks?.includes(tId)))
        const taskIdsAtTop = [...(props.priorityTasks || []), ...tasksIdsWithoutPriorityTasks,]
        tasksIds = taskIdsAtTop
    }

    let tasksList = tasksIds.filter((t) => !!tasks[t]).map(key => tasks[key]) as Task[]

    if (props.listType === 'JustYesterdayIncomplete') {
        tasksList = tasksList.filter((task) => !task.checked)
    }

    if (props.filterIndex || props.filter) {
        tasksList = tasksList.filter(filterFunc)
    }


    if (props.extraSpaceAtEnd) {
        tasksList.push({
            id: "blank",
            title: "",
            checked: false,
            checked_id: "",
            type: "DAILY_ROUTINE",
            date: "",
            created_by: "",
            created_at: 0,
            updated_at: 0,
            active: true,
            is_clonable: false
        })
    }

    const adjustedHeight = props.listType === 'Daily' && ((tasksList.length * ITEM_HEIGHT) >= listHeight) && (props.adjustToScreen) ? listHeight : undefined;

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        if (props.listType === 'SaveForLater') {
            loadSaveForLaterTasks().then(() => setRefreshing(false));
        } else if (props.listType === 'JustYesterdayIncomplete') {
            loadLastDateTasks().then(() => setRefreshing(false));
        }
        else {
            loadAllTasks().then(() => setRefreshing(false));
        }
    }, []);

    function RenderTaskItem(taskInfo: ListRenderItemInfo<Task>) {
        return <TaskItemView key={taskInfo.item.id} id={taskInfo.item.id} task={taskInfo.item} isFocused={(props.listType === 'SaveForLater' || props.listType === 'JustYesterdayIncomplete') ? selectTaskIds[taskInfo.item.id] : taskInfo.item.id === focusedTaskId} setAsFocused={setASelectedItemId} userId={userId} reloadAllTasks={loadAllTasks} navigation={props.navigation} hideCheckbox={props.listType === 'SaveForLater' || props.listType === 'JustYesterdayIncomplete'} listType={props.listType} contextColor={taskInfo.item.context_id ? props.contexts[taskInfo.item.context_id].color : undefined} isSearchResult={props.isSearchResults} enableShowTaskButton hideArrowButton={props.hideArrowButton} />
    }

    function paddingResolver(paddingValue: boolean | number) {
        if (typeof paddingValue === 'boolean') {
            return 8
        }
        if (typeof paddingValue === 'number') {
            return paddingValue
        }
        return
    }

    if (!!props.onEmptyListRender && tasksList.length === 1 && props.didLoadTodayTasksOnce) {
        return <View style={adjustedHeight
            ? {
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                marginTop: -10 + (props.marginTop ?? 0),
                height: adjustedHeight,
                paddingHorizontal: props.paddingInCell
                    ? paddingResolver(props.paddingInCell)
                    : 0
            }
            : {
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                marginTop: -10 + (props.marginTop ?? 0),
                paddingHorizontal: props.paddingInCell
                    ? paddingResolver(props.paddingInCell)
                    : 0
            }}>
            {props.onEmptyListRender}
        </View>
    }

    // const viewRef = React.useRef()

    // console.log(listRef.current)

    return <View style={{ flex: 1, display: 'relative' }}>

        <FlatList
            // ref={listRef as any}
            nestedScrollEnabled
            showsVerticalScrollIndicator={false}
            refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    tintColor={'white'}
                />}
            bounces={!props.disableRefresh}
            data={tasksList}
            extraData={{ selectTaskIds, listType: props.listType }}
            style={adjustedHeight
                ? {
                    marginTop: -10 + (props.marginTop ?? 0),
                    height: adjustedHeight,
                    paddingHorizontal: props.paddingInCell
                        ? paddingResolver(props.paddingInCell)
                        : 0
                }
                : {
                    marginTop: -10 + (props.marginTop ?? 0),
                    paddingHorizontal: props.paddingInCell
                        ? paddingResolver(props.paddingInCell)
                        : 0,

                }
            }
            initialNumToRender={8}
            keyExtractor={keyExtractor}
            renderItem={RenderTaskItem}
        />
        {showLoading && !refreshing && !props.hideLoader &&
            <View style={adjustedHeight
                ? {
                    height: adjustedHeight,
                    paddingHorizontal: props.paddingInCell
                        ? paddingResolver(props.paddingInCell)
                        : 0,
                    display: "absolute",
                    flex: 1,
                    justifyContent: 'center',
                    itemsCenter: 'center'
                }
                : {
                    bottom: Platform.OS === 'web' ? 'calc(50% - 50px)' : height * 0.4,
                    display: "absolute",
                    zIndex: 20,
                }}>
                <ActivityIndicator color={'white'} />
            </View>}
    </View>
}


const mapStateToProps = function (state: any) {
    return {
        focusedTaskId: state.visual.focusedTaskId,
        allTasks: state.allTasks,
        userId: state.user.userId,
        contexts: state.contexts,
        didLoadTodayTasksOnce: state.visual.didLoadTodayTasksOnce,
        showLoadingForList: state.visual.showLoadingForList
    }
}

export default connect(mapStateToProps)(ItemsFlatList)
