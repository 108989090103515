import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, TouchableWithoutFeedback, Platform } from 'react-native';
import Task, { MappedTasks } from '../types/Task';
import { parseCurrentDateTime } from '../utils/date';
import ItemsFlatList from './ItemsFlatList';
import { connect } from 'react-redux';
import cColors from '../styles/componentColors';
import { setTopBarColor } from '../utils/platform';
import { getOS } from '../utils/env';
import { Orientation } from 'expo-screen-orientation';
import { isLandscape } from '../utils/orientation';
const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';

//@ts-ignore
const isTauri: boolean = !!window.__TAURI__;

const styles = StyleSheet.create({
  topBar: {
    top: 0,
    paddingTop: 28 * 2,
    paddingBottom: 0,
    backgroundColor: cColors.TOP_BAR_BG_COLOR,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginBottom: 0,
    zIndex: 10,
  },
  topBarWithSelected: {
    height: 210,
    top: 0,
    paddingTop: 28 * 2,
    paddingBottom: 0,
    backgroundColor: cColors.TOP_BAR_BG_COLOR,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginBottom: 0,
    zIndex: 10,
  },
  topBarWeb: {
    top: 0,
    paddingBottom: 0,
    backgroundColor: cColors.TOP_BAR_BG_COLOR,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginBottom: 12,
    zIndex: 10,
  },
  topBarOpen: {
    top: 0,
    paddingTop: 28 * 2,
    paddingBottom: 0,
    backgroundColor: cColors.TOP_BAR_BG_COLOR,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginBottom: 12,
    zIndex: 10,
    height: '100%'
  },
  topBarWebOpen: {
    top: 0,
    paddingBottom: 0,
    backgroundColor: cColors.TOP_BAR_BG_COLOR,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    zIndex: 10,
    height: "100%"
  },
  topBarTextView: {
    paddingHorizontal: 18,
    paddingBottom: 8,
    display: "flex",
    flexDirection: 'row',
  },
  content: {
    justifyContent: 'center',
    flex: 1
  },
  icon: {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: cColors.TOP_BAR_ICON_COLOR,
    borderRadius: 90
  },
  iconLetter: {
    fontSize: 18,
    textAlign: 'center'
  },
  dailyTaskLabel: {
    color: cColors.TOP_BAR_LABEL_TEXT_COLOR,
    fontSize: 16,
    paddingBottom: 8
  }
});

interface TopBarProps {
  children?: any,
  style?: any,
  navigation: any,
  allTasks: { [key: string]: MappedTasks },
  didRequestDailyTasks: boolean,
  username: string,
  firstName: string,
  onPress: (expanded: boolean) => void,
  focusedTaskId: string,
  enableNotification: boolean
  orientation: Orientation
}

const TopBar = (props: TopBarProps) => {
  const os = getOS()

  const { allTasks, didRequestDailyTasks } = props
  const [expanded, setExpanded] = useState(false)
  const viewRef = React.useRef() as React.MutableRefObject<any>;

  const onPress = () => {
    if (didRequestDailyTasks && Object.keys(allTasks['Daily']).length > 0) {
      setExpanded(!expanded)
      props.onPress(!expanded)
    }
  }
  const onIconPress = () => {
    props.navigation.navigate('SettingsScreen')
  }

  const onLongPress = () => {

  }

  useEffect(() => {
    setTopBarColor()
    const shortcutHandler = (event: any) => {
      if ((event.code === 'KeyD' && event.metaKey) || (event.ctrlKey && event.code === 'KeyD')) {
        setExpanded(!expanded)
        props.onPress(!expanded)
      }
    }

    if (viewRef && viewRef.current && Platform.OS === 'web') {
      viewRef.current.setAttribute('tabindex', -1);
    }

    //@ts-ignore
    if (window.__TAURI__) {
      document.body.addEventListener("keypress", shortcutHandler)
    }
    return () => {
      //@ts-ignore
      if (window.__TAURI__) {
        document.body.removeEventListener('keypress', shortcutHandler)
      }
    }
  }, [expanded])

  const topBarWebStyle = {
    ...(expanded
      ? styles.topBarWebOpen
      : styles.topBarWeb),
    paddingTop: (isTauri && os.platform === 'darwin'
      ? 10
      : 20
    )
  };

  const uncheckedTasks = Object.keys(allTasks['Daily']).filter(taskKey => !allTasks['Daily'][taskKey].checked)
  const selectTask: Task | undefined = allTasks['Daily'][props.focusedTaskId]
  return <View
    style={
      Platform.OS === 'web'
        ? topBarWebStyle
        : expanded
          ? styles.topBarOpen
          : selectTask
            ? styles.topBarWithSelected
            : styles.topBar}>
    <TouchableWithoutFeedback onPress={onPress} onLongPress={onLongPress} >
      <View style={styles.topBarTextView} ref={viewRef}>
        <View style={styles.content}>
          <Text style={{ color: cColors.TOP_BAR_DATE_TEXT_COLOR, fontSize: 30, paddingBottom: 6 }} selectable={false}> {parseCurrentDateTime()} </Text>
          {didRequestDailyTasks && (!expanded && uncheckedTasks.length === 0)
            ? <Text style={styles.dailyTaskLabel} selectable={false}> {`Daily Tasks Complete`} </Text>
            : <Text style={styles.dailyTaskLabel} selectable={false}> {`${uncheckedTasks.length} Tasks Needed for Daily Routine`} </Text>
          }
        </View>
        <TouchableWithoutFeedback onPress={onIconPress} >
          <View style={styles.icon}>{props.username.length > 0 ? <Text style={styles.iconLetter}>{`${props.username[0]}`}</Text> : props.firstName.length > 0 ? <Text style={styles.iconLetter}>{`${props.firstName[0]}`}</Text> : null}</View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
    {expanded && <View style={{ paddingTop: Platform.OS === 'web' ? 13 : 0 }} />}
    {expanded && <>
      <ItemsFlatList
        listType='Daily'
        navigation={props.navigation}
        adjustToScreen={isMobile}
        paddingInCell={Platform.OS === 'web' ? 6 : false}
        hideArrowButton={!isLandscape(props.orientation)}
      />
    </>}

    {!expanded && selectTask && <View style={{ paddingTop: Platform.OS === 'web' ? 13 : 0 }} />}
    {!expanded && selectTask && <ItemsFlatList
      listType='Daily'
      navigation={props.navigation}
      filter={(task) => task.id === props.focusedTaskId}
      disableRefresh={true}
      paddingInCell={Platform.OS === 'web' ? 6 : false}
      hideArrowButton={!isLandscape(props.orientation)}
    />}
    {!expanded && selectTask && <View style={{ paddingTop: Platform.OS === 'web' ? 10 : 0 }} />}
  </View >
}
const mapStateToProps = function (state: any) {
  return {
    focusedTaskId: state.visual.focusedTaskId,
    didRequestDailyTasks: state.visual.didRequestDailyTasks,
    allTasks: state.allTasks,
    userId: state.user.userId,
    username: state.user.username,
    firstName: state.user.user_data ? state.user.user_data.first_name : "",
    enableNotification: state.visual.enableNotification
  }
}
export default connect(mapStateToProps)(TopBar)
