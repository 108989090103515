import { useEffect, useRef } from "react"
import { Platform, View } from "react-native"
import cColors from "../styles/componentColors"
import { Edges, SafeAreaView } from "react-native-safe-area-context"


export default function CustomContainer({ children, backgroundColor }: { children: any, backgroundColor?: string,  }) {
    const containerRef = useRef() as any
    useEffect(() => {
        // console.log(containerRef.current)
        if (containerRef.current) {
            containerRef.current.classList.add("size-container")
        }
    }, [])
    if (Platform.OS !== "web") {
        return <>{children}</>
    }
    return <View style={{ height: '100%', width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'row', backgroundColor: backgroundColor ? backgroundColor : cColors.PAGE_BG_COLOR }}>
        <View style={{ height: '100%', flex: 1 }} ref={containerRef}>
            {children}
        </View>
    </View>
}