import { Orientation } from "expo-screen-orientation";
import { DeviceActions } from "./deviceStateActions";

export type DeviceState = {
    deviceOrientation: Orientation
}

let deviceStateInit: DeviceState = { deviceOrientation: Orientation.UNKNOWN }

export function deviceStateReducer(state = deviceStateInit, { payload, type }: { type: string, payload?: Orientation }): DeviceState {
    switch (type) {
        case DeviceActions.SET_APP_ORIENTAION:
            return { ...state, deviceOrientation: !payload ? Orientation.UNKNOWN : payload }
        default:
            return state
    }
}
