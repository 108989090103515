import {
  StyleSheet,
  View,
  Text,
  Platform,
  Keyboard,
  Dimensions,
  Switch,
  Clipboard,
} from "react-native";
import React from "react";
import { Path, Svg } from "react-native-svg";
import { contextColors, contextColorsByHex } from "../colors";
import cColors from "../styles/componentColors";
import { SafeAreaView } from "react-native-safe-area-context";
import ModalContainer from "../components/ModalContainer";
import TextInput from "../components/TextInput";
import { useEffect, useRef, useState } from "react";
import BottomButton from "../components/BottomButton";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { saveContext, updateContext } from "../services/contextService";
import { warningToast } from "../utils/toast";
import { RootSiblingParent } from "react-native-root-siblings";
import { connect } from "react-redux";
import { Context } from "../types/Context";
import { TouchableOpacity } from "react-native-gesture-handler";
import {
  setContextPublic,
  setContextPrivate,
} from "../services/contextService";
import CustomContainer from '../components/CustomContainer';


const CopyIcon = () => (
  <Svg
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="white"
    height={20}
    width={20}
  >
    <Path
      strokeLinecap="round"
      stroke-linejoin="round"
      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
    />
  </Svg>
);

const { height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  header: {
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 22,
    color: cColors.HEADER_TEXT_COLOR,
  },
  container: {
    backgroundColor: cColors.PAGE_BG_COLOR,
    flex: 1,
    paddingHorizontal: 14,
    display: "flex",
  },
  text: {
    color: "white",
  },
  label: {
    fontSize: 20,
    marginBottom: -36,
    color: cColors.LABEL_TEXT_COLOR,
  },
  headerContainer: {
    paddingVertical: 6,
  },
});

const CreateContextScreen = (props: {
  navigation: any;
  route: any;
  contexts: any;
}) => {
  const contextId =
    props.route.params && props.route.params.contextId
      ? props.route.params.contextId
      : null;
  const publicLink = `https://list.minilist.app/${contextId}`;
  const context: Context | null =
    contextId && props.contexts[contextId] ? props.contexts[contextId] : null;
  const inputEl = useRef(null);
  const once = useRef(false);
  const [contextName, setContextName] = useState<string>(
    context ? context.name : ""
  );
  const [patten, setPattern] = useState<string>("");
  const [contextColorName, setContextColorName] = useState<string>(
    context && context.color
      ? contextColorsByHex[context.color]
        ? contextColorsByHex[context.color]
        : "custom-color"
      : ""
  );
  const [customColor, setCustomColor] = useState<string>(
    context && context.color && !contextColorsByHex[context.color]
      ? context.color
      : "white"
  );
  const onButtonPress = () => { };
  const createContext = () => {
    if (contextName) {
      if (contextColorName) {
        if (!contextId) {
          saveContext({
            id: "",
            name: contextName,
            pattern: "",
            created_by: "",
            created_at: 0,
            updated_at: 0,
            color: !!contextColorName
              ? contextColorName === "custom-color"
                ? customColor
                : contextColors[contextColorName]
              : "",
            active: true,
            is_public: isPublicEnabled,
          })
            .then((c) => {
              // console.log(c)
            })
            .catch((e) => { });
          props.navigation.goBack();
        } else {
          updateContext({
            ...context,
            name: contextName,
            color: !!contextColorName
              ? contextColorName === "custom-color"
                ? customColor
                : contextColors[contextColorName]
              : "",
            is_public: isPublicEnabled,
          } as Context)
            .then((c) => {
              // console.log(c)
            })
            .catch((e) => { });
          props.navigation.goBack();
        }
      } else {
        warningToast("Please choose a color", "bottom");
      }
    } else {
      warningToast("Context name cannot be blank", "bottom");
    }
  };
  const copyToClipboard = () => {
    Clipboard.setString(publicLink);
    warningToast("Copied Link", "bottom", 130)
  };
  const [isPublicEnabled, setIsPublicEnabled] = useState(context ? context.is_public : false);
  const toggleSwitch = () => {
    if (!!contextId) {
      if (isPublicEnabled) {
        setContextPrivate(contextId);
      } else {
        setContextPublic(contextId);
      }
    }
    setIsPublicEnabled((previousState) => !previousState);
  };

  useEffect(() => {
    const focusInputWeb = () => {
      // @ts-ignore
      inputEl.current.focus();
    };

    if (Platform.OS === "web" && !once.current) {
      once.current = true;
      focusInputWeb();
    }

    const shortcutHandler = (event: any) => {
      if (
        (event.code === "Enter" && event.metaKey) ||
        (event.ctrlKey && event.code === "Enter")
      ) {
        onButtonPress();
      }
    };

    // @ts-ignore
    if (window.__TAURI__) {
      document.body.addEventListener("keypress", shortcutHandler);
    }
    return () => {
      // @ts-ignore
      if (window.__TAURI__) {
        document.body.removeEventListener("keypress", shortcutHandler);
      }
    };
  }, [onButtonPress, contextName, props.navigation]);

  return (
    <ModalContainer navigation={props.navigation}>
      <CustomContainer>

        <TouchableWithoutFeedback
          style={{ height: height - 100 }}
          onPress={() => {
            if (Platform.OS === "ios") {
              Keyboard.dismiss();
            }
          }}
        >
          <RootSiblingParent>
            <SafeAreaView style={styles.container} edges={["left"]}>
              <View style={styles.headerContainer}>
                <Text style={styles.header}>{"Create New Context"}</Text>
              </View>
              <TextInput
                ref={inputEl}
                style={{
                  color: "white",
                  fontSize: 26,
                  paddingTop: 0,
                  paddingBottom: 12,
                  border: 'none',
                  paddingLeft: 0
                }}
                placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                placeholder="Context Name"
                onChangeText={(text) => setContextName(text)}
                defaultValue={contextName}
                autoFocus={
                  (Platform.OS === "ios" || Platform.OS === "android") &&
                  !contextId
                }
              />
              {false && (
                <TextInput
                  style={{
                    color: "white",
                    fontSize: 26,
                    paddingTop: 0,
                    paddingBottom: 12,
                    paddingLeft: 0
                  }}
                  placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                  placeholder="Pattern"
                  onChangeText={(text) => setPattern(text)}
                  defaultValue={patten}
                />
              )}
              <View style={styles.headerContainer}>
                <Text style={styles.header}>{"Set Color"}</Text>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: 20,
                  height: 70,
                }}
              >
                {Object.keys(contextColors)
                  .slice(0, 5)
                  .map((colorName: string) => (
                    <TouchableWithoutFeedback
                      onPress={() => {
                        if (colorName === contextColorName) {
                          setContextColorName("");
                        } else {
                          setContextColorName(colorName);
                        }
                      }}
                    >
                      <View
                        style={{
                          borderColor: contextColors[colorName],
                          width: 62,
                          height: 62,
                          borderRadius: 9999,
                          borderWidth: colorName === contextColorName ? 2 : 0,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: contextColors[colorName],
                            width: 50,
                            height: 50,
                            borderRadius: 9999,
                          }}
                        />
                      </View>
                    </TouchableWithoutFeedback>
                  ))}
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: 20,
                  height: 70,
                }}
              >
                {Object.keys(contextColors)
                  .slice(-5)
                  .map((colorName: string) => (
                    <TouchableWithoutFeedback
                      onPress={() => {
                        if (colorName === contextColorName) {
                          setContextColorName("");
                        } else {
                          setContextColorName(colorName);
                        }
                      }}
                    >
                      <View
                        style={{
                          borderColor: contextColors[colorName],
                          width: 62,
                          height: 62,
                          borderRadius: 9999,
                          borderWidth: colorName === contextColorName ? 2 : 0,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: contextColors[colorName],
                            width: 50,
                            height: 50,
                            borderRadius: 9999,
                          }}
                        />
                      </View>
                    </TouchableWithoutFeedback>
                  ))}
              </View>
              <View
                style={{
                  marginTop: 20,
                  width: 270,
                  // backgroundColor: 'red',
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <TouchableWithoutFeedback
                  onPress={() => {
                    if ("custom-color" === contextColorName) {
                      setContextColorName("");
                    } else {
                      setContextColorName("custom-color");
                    }
                  }}
                >
                  <View
                    style={{
                      borderColor: customColor,
                      width: 62,
                      height: 62,
                      borderRadius: 9999,
                      borderWidth: "custom-color" === contextColorName ? 2 : 0,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: customColor,
                        width: 50,
                        height: 50,
                        borderRadius: 9999,
                      }}
                    />
                  </View>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback
                  onPress={() => {
                    props.navigation.navigate("CustomColorScreen", {
                      customColor,
                      setCustomColorCb: setCustomColor,
                    });
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        alignItems: "center",
                        fontSize: 20,
                        color: "white",
                      }}
                    >
                      Change custom color
                    </Text>
                  </View>
                </TouchableWithoutFeedback>
              </View>
              <View
                style={{
                  width: "100%",
                  paddingVertical: 20,
                }}
              >
                <View style={styles.headerContainer}>
                  <Text style={styles.header}>{"Make context public"}</Text>
                </View>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <Switch
                    trackColor={{ false: "white", true: "white" }}
                    thumbColor={
                      isPublicEnabled
                        ? cColors.SWITCH_THUMB_COLOR_ENABLED
                        : cColors.SWITCH_THUMB_COLOR_DISABLED
                    }
                    ios_backgroundColor={cColors.SWITCH_IOS_BG_COLOR}
                    onValueChange={toggleSwitch}
                    value={isPublicEnabled}
                  />
                  <View style={{
                    paddingHorizontal: 20,
                    paddingVertical: 3
                  }}>
                    <Text style={{
                      color: cColors.PUBLIC_CONTEXT_LABEL_TEXT_COLOR,
                      fontSize: 20
                    }}>
                      {`This context is ${isPublicEnabled ? "public" : "private"
                        }`}
                    </Text>
                  </View>
                </View>
                {isPublicEnabled && (
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      paddingVertical: 14,
                      justifyContent: "center",
                      cursor: "pointer"
                    }}
                  >
                    <TouchableOpacity onPress={() => copyToClipboard()}>
                      <View style={{ flexDirection: "row" }}>
                        <CopyIcon />
                        <Text
                          style={{
                            color: cColors.PUBLIC_CONTEXT_LABEL_TEXT_COLOR,
                            fontSize: 20,
                            textAlign: "center",
                            paddingHorizontal: 10,
                            //   width: ",
                          }}
                        >
                          {"Copy Link"}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </SafeAreaView>
          </RootSiblingParent>
        </TouchableWithoutFeedback>
        <BottomButton
          singleTap={createContext}
          text={!contextId ? "Create Context" : "Save Context"}
        />
      </CustomContainer>
    </ModalContainer>
  );
};

const mapStateToProps = function (state: any) {
  return {
    contexts: state.contexts,
  };
};

export default connect(mapStateToProps)(CreateContextScreen);
