import * as React from 'react';
import { View, Text, Platform, StyleSheet, Pressable, TouchableWithoutFeedback } from 'react-native';
import { BUTTON_WIDTH_HEIGHT, marginDist } from '../components/IconBottomButton';
import { connect } from 'react-redux';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import { Context, MappedContexts } from '../types/Context';
import cColors from "../styles/componentColors"
import { Dimensions } from 'react-native';
import { MappedTasks } from '../types/Task';
import chroma from 'chroma-js';
const { width, height } = Dimensions.get('screen')

const aboveButtonBottomDist = (Platform.OS === 'web' ? 10 : 30) + BUTTON_WIDTH_HEIGHT;
const styles = StyleSheet.create({
    title: {
        paddingTop: 0,
        paddingBottom: 12,
        fontSize: 24,
        color: cColors.MODAL_TITLE_TEXT_COLOR
    },
    container: {
        flex: 1,
        backgroundColor: cColors.PAGE_BG_COLOR,
        paddingHorizontal: 12
    },
    containerMobile: {
        width: width,
        paddingHorizontal: 12
    },
    page: {
        width: Platform.OS === 'android' || Platform.OS === 'ios' ? width : '100%',
        flex: 1,
        paddingHorizontal: 0,
        display: 'flex',
        backgroundColor: cColors.PAGE_BG_COLOR
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 14
    },
    categoryContainer: {
        flexDirection: 'row',
        marginBottom: 10,
        height: 70,
    },
    category: {
        borderRadius: 9999,
        display: 'flex',
        paddingHorizontal: 12,
        paddingVertical: 12,
        marginRight: 6,
        borderWidth: 1,
        borderColor: cColors.DEFAULT_CONTEXT_BUBBLE_TEXT_COLOR,
        // backgroundColor: cColors.DEFAULT_CONTEXT_BUBBLE_BG_COLOR,
        flexDirection: 'row'
    },
    categorySelected: {
        borderRadius: 9999,
        display: 'flex',
        paddingHorizontal: 14,
        paddingVertical: 12,
        marginRight: 8,
        backgroundColor: cColors.CONTEXT_BUBBLE_SELECTED_BG_COLOR,
    },
    categoryName: {
        color: 'white',
        fontSize: 18
    },
    categoryNameSelected: {
        color: cColors.CONTEXT_BUBBLE_SELECTED_TEXT_COLOR,
        fontSize: 18
    },
    contextScrollStyle: {
        display: 'flex',
    }
})


const isTablet = (Platform.OS === 'ios' || Platform.OS === 'android') && width >= 768

function getMargin(isLandscape: boolean) {
    if (isTablet && isLandscape) {
        return 12
    }
    return marginDist
}


function FocusContextMenu(props: { isLandscape: boolean, contexts: MappedContexts, focusContext: string | null, todaysTasks: MappedTasks, setFocusContext: (contextId: string | null, contextColor: string, textColor: string) => void, }) {

    const margin = getMargin(props.isLandscape)

    let filterIds = Array.from(new Set((Object.keys(props.todaysTasks).map(tId => props.todaysTasks[tId].context_id))))

    let filteredContextsIds = (Object.keys(props.contexts) as string[])
        .filter(c => {
            return filterIds.includes(c)
        })
    filteredContextsIds.unshift("All")
    filteredContextsIds.reverse()
    return (<>
        {filteredContextsIds.map((contextId, i) => {
            const contextObj = contextId === 'All' ? { color: cColors.DEFAULT_CONTEXT_BUBBLE_BG_COLOR, name: 'Show All' } : props.contexts[contextId]
            const contextColor: string = ((contextObj as Context).color ? (contextObj as Context).color : cColors.DEFAULT_CONTEXT_BUBBLE_BG_COLOR) as string;

            const contrast = chroma.contrast(cColors.DEFAULT_CONTEXT_BUBBLE_TEXT_COLOR_DARK, contextColor as string) < 4.5
            const textColor = contrast ? 'white' : 'black';
            const outlineColor = !contrast ? chroma(contextColor).darken() : chroma(contextColor).brighten()

            return <View
                key={contextId}
                style={Platform.OS === 'web'
                    ? {
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 12,
                        justifyContent: 'flex-end',
                        bottom: aboveButtonBottomDist + (BUTTON_WIDTH_HEIGHT * .8 * i),
                        right: 'calc(10% - 15px)',
                        cursor: 'none'
                    }
                    : {
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 12,
                        justifyContent: 'flex-end',
                        bottom: aboveButtonBottomDist + (BUTTON_WIDTH_HEIGHT * .8 * i),
                        right: margin
                    }}>
                <TouchableWithoutFeedback
                    onPress={() => {
                        if (contextId === 'All') {
                            props.setFocusContext(null, '', textColor)
                        } else {
                            props.setFocusContext(contextId, contextObj.color as string, textColor)
                        }
                    }}
                    style={{}}
                >
                    <View style={{ ...styles.category, borderColor: outlineColor, backgroundColor: contextColor, cursor: 'pointer' }}>
                        <Text style={{ ...styles.categoryName, color: textColor, }}>{contextObj.name}</Text>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        })}

    </>);
}



const mapStateToProps = function (state: any) {
    return {
        contexts: state.contexts,
        todaysTasks: state.allTasks['JustToday']
    }
}

export default connect(mapStateToProps)(FocusContextMenu)
