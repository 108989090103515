import * as React from 'react';
import { View, Text, Platform, Pressable, StyleSheet, Dimensions } from "react-native";
import cColors from "../styles/componentColors";
import Task from '../types/Task';
import BottomButton from './BottomButton';
import { parseCurrentDateTimeIso } from '../utils/date';
import TextInput from './TextInput';
import { Page } from '../screens/AddMulipleTasksScreen';
import Svg, { Path, } from 'react-native-svg';
const { width } = Dimensions.get('window');

export const manyContainer = {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 14,
    backgroundColor: cColors.PAGE_BG_COLOR,
    width: Platform.OS === 'web' ? "100%" : width,
    paddingTop: 0,
    paddingLeft: 12,
    paddingRight: 12
}

interface ManyContainerViewProps { children: any, style: any }

function WebManyContainerView(props: ManyContainerViewProps) {
    return <div style={props.style}>{props.children}</div>
}

function ManyContainerView(props: ManyContainerViewProps) {
    const rnStyle = StyleSheet.create({ style: props.style })
    return <View style={rnStyle.style}>{props.children}</View>
}

export const ManyContainer = Platform.select({
    'web': WebManyContainerView,
    'default': ManyContainerView
})

export const CreateMulitpleTasksForm = (props: { addTaskToPages: (task: Task) => void; pages: Page[]; createAllTasks: (finalTask?: Task) => void; editTitle: (i: number, title: string) => void; deletePage: (index: number) => void }) => {
    const inputEl = React.useRef(null);
    const shouldFocus = React.useRef(false);
    const [newTitle, setNewTitle] = React.useState<string>('');
    const [focusedTitle, setFocusedTitle] = React.useState('');
    const saveAllTasks = async () => {
        props.createAllTasks(newTitle.length > 0 ? {
            "id": "",
            "title": newTitle,
            "type": 'JUST_TODAY',
            "details": '',
            "date": parseCurrentDateTimeIso(),
            "checked": false,
            "checked_id": "",
            "active": true,
            "created_by": "swag",
            "created_at": 0,
            "updated_at": 0,
            is_clonable: false
        } : undefined);
    };

    const addTaskToPages = () => {
        props.addTaskToPages(
            {
                "id": "",
                "title": newTitle,
                "type": 'JUST_TODAY',
                "details": '',
                "date": parseCurrentDateTimeIso(),
                "checked": false,
                "checked_id": "",
                "active": true,
                "created_by": "swag",
                "created_at": 0,
                "updated_at": 0
            } as Task
        );
    };

    const afterPress = () => {
        if (inputEl.current) {
            // @ts-ignore
            inputEl.current.focus()
        }

    };

    const onKeyPress = (event: any) => {
        if (((event.code === 'Enter' && event.metaKey) || (event.ctrlKey && event.code === 'Enter'))) {
            saveAllTasks();
        } else {
            addTaskToPages();
            setNewTitle('')
            setTimeout(afterPress, 100)
            shouldFocus.current = true
        }
    };

    const onEditingTitleChange = (i: number, title: string) => {
        // if (delayDebounceFn) {
        //     clearTimeout(delayDebounceFn)
        // }
        props.editTitle(i, title);
        // delayDebounceFn = setTimeout(() => {
        //     console.log("with changes ", title)
        //     if (title !== '') {
        //     }
        // }, 40)
    };

    const deleteItem = (index: number) => {
        if (props.pages.length === 1) {
            shouldFocus.current = true
        } else {
            shouldFocus.current = false
        }
        props.deletePage(index)
    }

    React.useEffect(() => {
        const focusInput = () => {
            if (shouldFocus.current && inputEl && inputEl.current) {
                // @ts-ignore
                inputEl.current.focus();
            }
        };

        focusInput();

        return () => {
        };
    }, [saveAllTasks, inputEl, newTitle]);

    return <ManyContainer style={manyContainer}>
        <View style={styles.headerContainer}>
            <Text style={styles.header}>{"Create Many Tasks"}</Text>
        </View>
        {props.pages.map((page, i) => <View
            key={i}
            style={{ width: '100%', flexDirection: 'row' }}
        >
            <TextInput
                style={{
                    color: 'white',
                    fontSize: 26,
                    paddingTop: 0,
                    paddingBottom: 12,
                    width: Platform.OS === 'web' ? 'calc(100% - 72px)' : width - 60 - 12,
                    border: 'none',
                    paddingLeft: 0
                }}
                placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                placeholder="Title of task"
                onChangeText={text => {
                    onEditingTitleChange(i, text);
                }}
                defaultValue={page.task.title}
                returnKeyType="next"
            />
            <Pressable onPress={() => {
                deleteItem(i)
            }}>
                <View style={{ width: 60, height: 50, justifyContent: 'center', alignItems: "center" }}>
                    {/* @ts-ignore */}
                    <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" height={30} width={30}>
                        <Path strokeLinecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </Svg>
                </View>
            </Pressable>
        </View>
        )}

        <TextInput
            ref={inputEl}
            style={{
                color: 'white',
                fontSize: 26,
                paddingTop: 0,
                paddingBottom: 12,
                width: Platform.OS === 'web' ? 'calc(100% - 50px)' : width - 50,
                border: 'none',
                paddingLeft: 0
            }}
            placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
            placeholder="Title of task"
            onChangeText={text => setNewTitle(text)}
            value={newTitle}
            defaultValue={newTitle}
            onSubmitEditing={onKeyPress}
            returnKeyType="next"
            autoFocus />
        <BottomButton singleTap={saveAllTasks} text={`Create ${newTitle.length > 0 ? 1 + props.pages.length : props.pages.length} Tasks`} delay={0} />
    </ManyContainer>;
};


const styles = StyleSheet.create({
    header: {
        paddingTop: 0,
        paddingBottom: 12,
        fontSize: 22,
        color: cColors.HEADER_TEXT_COLOR
    },
    manyContainer: {
        paddingHorizontal: 14,
        backgroundColor: cColors.PAGE_BG_COLOR,
        flex: 1,
        width: Platform.OS === 'web' ? "100%" : width,

        paddingTop: 0
    },
    text: {
        color: 'white'
    },
    headerContainer: {
        paddingVertical: 6
    }
})