import { TasksActions } from "../tasks/tasksActions"
import { UserActions } from "../user/userActions"
import { VisualActions } from "./visualActions"

interface MenuState {
  taskId?: string,
}

export interface ShowLoadingForList {
  'Daily': boolean
  'JustToday': boolean
  'SaveForLater': boolean
  'JustYesterdayIncomplete': boolean
}

interface VisualState {
  focusedTaskId: string
  disallowSort: boolean
  didRequestDailyTasks: boolean
  customColor: string
  menu: MenuState
  enableNotification: boolean
  didLoadTodayTasksOnce: boolean
  didLoadSaveForLaterOnce: boolean
  showLoadingForList: ShowLoadingForList,
  displayTaskId: string | undefined
}

const INIT_STATE = {
  focusedTaskId: "",
  disallowSort: false,
  customColor: "white",
  menu: {} as MenuState,
  enableNotification: false,
  didRequestDailyTasks: false,
  didLoadTodayTasksOnce: false,
  showLoadingForList: {
    'Daily': false,
    'JustToday': false,
    'SaveForLater': false,
    'JustYesterdayIncomplete': false
  },
  didLoadSaveForLaterOnce: false,
  displayTaskId: undefined
} as VisualState

export function visualReducer(state: VisualState = INIT_STATE, action: { type: string, payload?: any }): VisualState {
  switch (action.type) {
    case TasksActions.START_LOAD_JUST_TODAY_TASKS_ACTION:
      return { ...state, showLoadingForList: { ...state.showLoadingForList, JustToday: true } }
    case TasksActions.SUCCESS_LOAD_JUST_TODAY_TASKS_ACTION:
      return { ...state, disallowSort: action.payload.disallowSort, didLoadTodayTasksOnce: true, showLoadingForList: { ...state.showLoadingForList, JustToday: false } }
    case TasksActions.START_LOAD_DAILY_TASKS_ACTION:
      return { ...state, showLoadingForList: { ...state.showLoadingForList, Daily: true } }
    case TasksActions.SUCCESS_LOAD_DAILY_TASKS_ACTION:
      return { ...state, didRequestDailyTasks: true, showLoadingForList: { ...state.showLoadingForList, Daily: false } }

    case TasksActions.START_LOAD_SAVE_FOR_LATER_TASKS_ACTION:
      return { ...state, showLoadingForList: { ...state.showLoadingForList, SaveForLater: true } }
    case TasksActions.SUCCESS_LOAD_SAVE_FOR_LATER_TASKS_ACTION:
      return { ...state, didLoadSaveForLaterOnce: true, showLoadingForList: { ...state.showLoadingForList, SaveForLater: false } }
    case TasksActions.SET_FOCUS_TASK_ID_ACTION:
      return { ...state, focusedTaskId: action.payload.focusedTaskId }
    case TasksActions.SET_DISPLAY_TASK_ID_ACTION:
      return { ...state, displayTaskId: action.payload.displayTaskId }
    case TasksActions.SUCCESS_LOAD_FOCUSED_TASK_ACTION:
      return { ...state, focusedTaskId: action.payload.focusedTaskId }
    case VisualActions.SET_TASK_ID_MENU:
      return { ...state, menu: { taskId: action.payload.taskId } }
    case VisualActions.TOGGLE_ENABLE_NOTIFICATION:
      return { ...state, enableNotification: action.payload.enableNotification }
    case VisualActions.RESET_TASK_ID:
      return { ...state, menu: { taskId: undefined } }
    case UserActions.SUCCESSFUL_LOGOUT_ACTION:
      return { ...INIT_STATE }
    default:
      return state
  }
}
