import React, { useState } from 'react'
import { useEffect, forwardRef, useImperativeHandle } from "react"
import { Button, LayoutRectangle, Platform, View } from "react-native"
import cColors from "../styles/componentColors"
import { removeDatesForAllLastDateTasks } from "../services/tasksService"
import { setDailyImportDate } from "../services/userService"

const ModalContainer = forwardRef((props: { children?: any, navigation: any, screen?: string, enableRightButton?: boolean, rightButtonOnClick?: (e: any) => void, rightButtonText?: string, rightButtonWidth?: number, paddingTop?: number, onLayout?: (layout: LayoutRectangle) => void }, ref: any) => {
    const [modalRect, setModalRect] = useState<LayoutRectangle>()
    useImperativeHandle(ref, () => ({
        close() {
            if (props.navigation) {
                props.navigation.goBack()
            }
        },
        getRect() { return modalRect }

    }));
    useEffect(() => {
        const escapeKeyPress = (event: any) => {
            if (event.keyCode === 27) {
                props.navigation.goBack()
            }
        }
        if (['web'].includes(Platform.OS)) {
            document.body.addEventListener('keypress', escapeKeyPress)
        }
        return () => {
            if (['web'].includes(Platform.OS)) {
                document.body.removeEventListener('keypress', escapeKeyPress)
            }
        }
    }, [props.navigation])
    const additionalPadding = typeof props.paddingTop === 'undefined' ? 0 : props.paddingTop
    return <>
        <View
            onLayout={({ nativeEvent: { layout } }) => { setModalRect(layout); props.onLayout && props.onLayout(layout) }}
            style={{
                width: '100%',
                backgroundColor: cColors.MODAL_PAGE_COLOR,
                paddingVertical: 10,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: Platform.OS === 'android' ? (additionalPadding + 35) : (additionalPadding + 0)
            }}>
            <View style={{ width: 82, display: 'flex', flexDirection: 'column', paddingVertical: 10 }}>
                <Button
                    color={Platform.OS === 'ios' ? cColors.MODAL_BUTTON_TEXT_COLOR_IOS : cColors.MODAL_BUTTON_TEXT_COLOR_NOT_IOS}
                    title="Cancel"
                    onPress={async () => {
                        if (props.screen === "OnEnterScreen") {
                            await setDailyImportDate()
                            await removeDatesForAllLastDateTasks()
                        }
                        props.navigation.goBack()
                    }}
                />
            </View>
            {props.enableRightButton && <View style={{ width: props.rightButtonWidth ? props.rightButtonWidth : 82, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginRight: 8, paddingVertical: 10 }}>
                <Button
                    color={Platform.OS === 'ios' ? cColors.MODAL_BUTTON_TEXT_COLOR_IOS : cColors.MODAL_BUTTON_TEXT_COLOR_NOT_IOS}
                    title={props.rightButtonText || ""}
                    onPress={props.rightButtonOnClick ? props.rightButtonOnClick : (e) => { }}
                />
            </View>}
        </View>
        <View style={{ width: '100%', flex: 1 }}>
            {props.children}
        </View>
    </>
})
export default ModalContainer