import React from 'react'
import { StyleSheet, Text, View, Platform, Dimensions } from 'react-native';
import { TouchableComponent } from './BottomButtonShared'
import cColors from '../styles/componentColors';
const BUTTON_WIDTH_HEIGHT = 70
const screenDimensions = Dimensions.get("screen");
const { width, height } = Dimensions.get("window");
const isTablet = (Platform.OS === 'ios' || Platform.OS === 'android') && width >= 768

const BottomButton = (props: { singleTap: () => void, doubleTap?: () => void, text: string, delay?: number, disabled?: boolean }) => {
    return <View
        style={styles.buttonContainer}
    >
        <TouchableComponent
            singleTap={props.singleTap}
            doubleTap={props.doubleTap}
            delay={props.delay || 250}
            disabled={props.disabled}
        >
            <View style={styles.buttonContent}>
                <Text style={styles.buttonText}>{props.text}</Text>
                {props.disabled && <View style={styles.overlay} />}
            </View>
        </TouchableComponent>
    </View>
}

const styles = StyleSheet.create({
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 9999,
    },
    buttonContainer: {
        position: 'absolute',
        bottom: Platform.OS === 'web' ? 10 : 30,
        alignSelf: 'center',
        zIndex: 1,
        width: Platform.OS === 'web' ? "40%" : undefined,
    },
    buttonContent: {
        backgroundColor: cColors.BOTTOM_BUTTON_COLOR,
        width: Platform.OS === 'web' ? "100%" : isTablet ? (200) : ((screenDimensions.width - 14) / 2),
        height: BUTTON_WIDTH_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        borderRadius: 9999,
        shadowColor: cColors.BOTTOM_BUTTON_SHADOW_COLOR,
        shadowOpacity: 0.3,
        shadowOffset: {
            width: 0,
            height: 5
        },
        paddingVertical: Platform.OS === 'web' ? 12 : undefined,
    },
    buttonText: {
        color: cColors.BUTTON_TEXT_COLOR,
        fontSize: 20,
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',

    }
});
export default BottomButton
