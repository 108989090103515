
import * as React from 'react';
import { View, Text, StyleSheet, Dimensions, Platform, LayoutRectangle } from "react-native"
import cColors from "../styles/componentColors"
import BottomButton from '../components/BottomButton';
import ItemsFlatList from '../components/ItemsFlatList';
import { useEffect } from 'react';
import { delay } from '../utils/delay';
import { addTaskIdToRecentTasks, loadAllTasks, loadSaveForLaterTasks, loadSearchResultTasks, moveTasksToJustToday, resetSearchResultTask } from '../services/tasksService';
import ModalContainer from '../components/ModalContainer';
import { warningToast } from '../utils/toast';
import { ScrollView } from "react-native-gesture-handler"
import ContextsView from '../components/ContextsView';
import { loadAllContexts } from '../services/contextService';
import ContextBubblesTopList from '../components/ContextBubblesTopList';
import SearchBar from '../components/SearchBar';
import debounce from 'lodash.debounce';
import IconBottomButton from '../components/IconBottomButton';
import Svg, { Path } from "react-native-svg";
import { DateTime } from 'luxon';
import { RemindLaterIcon } from '../components/Icons';
import { getUserMetaData } from '../services/userService';
import CustomContainer from "../components/CustomContainer";

const PAGE_COUNT = 2
export default ({ navigation, route }: { navigation: any, route: any }) => {
    const tomorrow = DateTime.now().plus({ days: 1 })
    const initSearch = route.params && !!route.params["initSearch"]
    const [didFocusOnInit, setDidFocusOnInit] = React.useState(false);
    const [loadedOnce, setLoadedOnce] = React.useState(false);
    const [selectTaskIds, setSelectedTaskIds] = React.useState<string[]>([]);
    const [recentTaskIds, setRecentTaskIds] = React.useState<string[]>([]);
    const scrollRef = React.useRef() as React.MutableRefObject<any>;
    const [scrollEnabled, setScrollEnabled] = React.useState(false)
    const [clicked, setClicked] = React.useState(false)
    const [isSearchResults, setIsSearchResults] = React.useState(false)
    const [searchPhrase, setSearchPhrase] = React.useState<string>("");
    const [page, setPage] = React.useState<"list" | "contexts">("list")
    const [refreshing, setRefreshing] = React.useState(false);
    const [filterIndex, setFilterIndex] = React.useState<string>("contextless");
    const [shouldUseAllOnNextSearch, setShouldUseAllOnNextSearch] = React.useState<boolean>(true)


    const searchBarRef = React.createRef()
    const [modalRect, setModalRect] = React.useState<LayoutRectangle>()
    const onKeyPress = () => {
        setRefreshing(false)
    }

    const onButtonPress = async () => {
        if (selectTaskIds.length > 0) {
            await moveTasksToJustToday(selectTaskIds)
            await delay(200)
            await loadAllTasks()
            await delay(200)
            navigation.goBack()
        } else {
            warningToast('Select some tasks to move', 'bottom')
        }
    }

    const onAddRecentButtonPress = async () => {
        if (selectTaskIds.length > 0) {
            await addTaskIdToRecentTasks(selectTaskIds)
            await delay(200)
            navigation.goBack()
        } else {
            warningToast('Select some tasks to move', 'bottom')
        }
    }

    const setTaskIds = (taskIds: string[]) => {
        setSelectedTaskIds(taskIds)
    }

    useEffect(() => {
        if (!!scrollRef.current) {
            if (Platform.OS === 'web') {
                scrollRef.current.children[0].children[0].style.width = "100%"
                scrollRef.current.children[0].children[1].style.width = "100%"
            }
            scrollRef.current.scrollTo({
                y: 0,
                animated: false
            })
        }
        if (initSearch && !didFocusOnInit) {
            setDidFocusOnInit(true)
            if (!!searchBarRef.current) {
                // @ts-ignore
                searchBarRef.current.focus(10)
            }
            if (shouldUseAllOnNextSearch) {
                setShouldUseAllOnNextSearch(false)
                setFilterIndex('all')
            }
            setClicked(true)
            setIsSearchResults(true)
        }

        const shortcutHandler = (event: any) => {
            if ((event.code === 'KeyB' && event.metaKey) || (event.ctrlKey && event.code === 'KeyB')) {
                navigation.goBack()
            }
        }

        //@ts-ignore
        if (window.__TAURI__) {
            document.body.addEventListener("keypress", shortcutHandler)
        }

        if (!loadedOnce) {
            setLoadedOnce(true)

            if (isSearchResults) {
                loadSearchResultTasks({ search_term: searchPhrase, context_id: filterIndex, task_type: "SAVE_FOR_LATER" })
            } else {
                loadSaveForLaterTasks();
                loadAllContexts()
            }
        }


        getUserMetaData()
            .then((userMetaData) => {
                setRecentTaskIds(userMetaData.recent_tasks)
            })
            .catch(e => {
            })

        return () => {
            //@ts-ignore
            if (window.__TAURI__) {
                document.body.removeEventListener('keypress', shortcutHandler)
            }
        }
    }, [isSearchResults, filterIndex, initSearch, shouldUseAllOnNextSearch, didFocusOnInit, loadedOnce])

    const handleSearchPhraseChange = (searchPhraseVal: string) => {
        // search term
        if (searchPhraseVal) {
            loadSearchResultTasks({ search_term: searchPhraseVal, context_id: filterIndex, task_type: "SAVE_FOR_LATER" })
        }

    }

    const debouncedHandleSearchPhraseChange = React.useMemo(
        () => debounce(handleSearchPhraseChange, 300)
        , [filterIndex]);


    return <ModalContainer
        onLayout={(rect) => { setModalRect(rect) }}
        navigation={navigation}
        enableRightButton
        rightButtonText={page === 'list' ? 'Contexts' : 'List'}
        rightButtonOnClick={(e) => {
            if (page === 'list') {
                setPage("contexts")
                scrollRef.current.scrollToEnd()
            } else {
                setPage('list')
                scrollRef.current.scrollTo({ y: 0 })
            }
            setScrollEnabled(true)
        }}
        rightButtonWidth={page === 'list' ? (Platform.OS === "web" ? 95 : Platform.OS === "android" ? 96 : 88) : 50}
    >
        <CustomContainer>
            <ScrollView
                ref={scrollRef}
                decelerationRate="fast"
                style={Platform.OS === 'android' || Platform.OS === 'ios' ? {} : styles.container}
                horizontal
                pagingEnabled
                scrollEnabled={scrollEnabled}
                onMomentumScrollEnd={() => {
                    setScrollEnabled(false)
                }}
                snapToEnd
                contentContainerStyle={Platform.OS === 'android' || Platform.OS === 'ios' ? { width: (modalRect ? modalRect.width : width) * PAGE_COUNT } : { flex: 1 }}
            >
                <View style={Platform.OS === 'android' || Platform.OS === 'ios' ? { ...styles.page, width: modalRect ? modalRect.width : width } : styles.page}>
                    <View style={styles.headerContainer}>
                        <Text style={styles.title}>{"Tasks for Later"}</Text>
                        <SearchBar
                            ref={searchBarRef}
                            clicked={clicked}
                            searchPhrase={searchPhrase}
                            setSearchPhrase={(phrase) => {
                                setSearchPhrase(phrase)
                                if (phrase === "") {
                                    resetSearchResultTask()
                                } else {
                                    setIsSearchResults(clicked)
                                }
                                debouncedHandleSearchPhraseChange.cancel()
                                debouncedHandleSearchPhraseChange(phrase)
                            }}
                            setClicked={(clickedVal) => {
                                if (shouldUseAllOnNextSearch) {
                                    setShouldUseAllOnNextSearch(false)
                                    setFilterIndex('all')
                                }
                                setLoadedOnce(false)
                                setIsSearchResults(clicked)
                                setClicked(clickedVal)
                                setIsSearchResults(clickedVal)
                            }}
                            onCancel={() => {
                                setShouldUseAllOnNextSearch(true)
                                setLoadedOnce(false)

                            }} />
                    </View>
                    <View
                        style={{
                            width: '100%',
                            marginBottom: Platform.OS === 'web' ? 8 : 2
                        }}>
                        <ContextBubblesTopList
                            isSearchResults={isSearchResults}
                            // @ts-ignore
                            setContextIndex={(index: string) => {
                                setFilterIndex(index)
                                setShouldUseAllOnNextSearch(index === 'all')
                            }}
                            navigation={navigation}
                            contextIndex={filterIndex}
                            marginSide={"left"}
                        />
                    </View>

                    <ItemsFlatList hideArrowButton listType={"SaveForLater"} navigation={navigation} setTaskIds={setTaskIds} extraSpaceAtEnd filterIndex={filterIndex} isSearchResults={isSearchResults} paddingInCell priorityTasks={recentTaskIds} />

                    <BottomButton disabled={selectTaskIds.length === 0} singleTap={onButtonPress} text={selectTaskIds.length === 0 ? `Bring to today` : `${selectTaskIds.length} tasks to today`} delay={0} />

                    {(filterIndex !== 'contextless' && filterIndex !== 'all') && <IconBottomButton
                        side="left"
                        isIcon
                        singleTap={() => {
                            navigation.navigate('AddTask', { withContext: filterIndex, withType: "SAVE_FOR_LATER" })
                        }}
                        buttonStyle='secondary'
                    >
                        {/* @ts-ignore */}
                        <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" height={Platform.OS === 'web' ? 27 : 50} width={Platform.OS === 'web' ? 27 : 50}
                            stroke={cColors.BOTTOM_BUTTON_COLOR}
                        >
                            {/* @ts-ignore */}
                            <Path strokeLinecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" strokeWidth="1.5" />
                        </Svg>
                    </IconBottomButton>}
                    <IconBottomButton
                        side="right"
                        isIcon
                        singleTap={onAddRecentButtonPress}
                        disabled={selectTaskIds.length === 0}
                        buttonStyle='secondary'
                    >
                        <View style={{
                            justifyContent: 'center',
                            alignContent: 'center',
                            paddingHorizontal: Platform.OS === 'web' ? 0 : 0,
                            marginVertical: Platform.OS === 'web' ? -3.5 : -5,
                            marginHorizontal: Platform.OS === 'web' ? -10 + 1.5 : 0
                        }}>
                            <View style={{
                                position: 'absolute',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                // backgroundColor: 'green',
                                marginLeft: Platform.OS === 'web' ? 0 : -2
                            }}>
                                <RemindLaterIcon
                                    height={Platform.OS === 'web' ? 45 : 50}
                                    width={Platform.OS === 'web' ? 45 : 50}
                                    color={cColors.BOTTOM_BUTTON_COLOR}
                                />
                            </View>
                            <Text style={{ fontSize: Platform.OS === 'web' ? 8 : 7, textAlign: 'center', alignContent: 'center', paddingTop: Platform.OS === 'web' ? 3 : 3, width: 44, color: cColors.BOTTOM_BUTTON_COLOR }}>{tomorrow.monthShort}</Text>
                            <Text style={{ fontSize: 20, alignContent: 'center', textAlign: 'center', width: 44, marginTop: 0, color: cColors.BOTTOM_BUTTON_COLOR }}>{tomorrow.day}</Text>
                        </View>
                    </IconBottomButton>
                </View>
                <ContextsView navigation={navigation} width={Platform.OS === 'android' || Platform.OS === 'ios' ? (modalRect ? modalRect.width : width) : '100%'} />
            </ScrollView >
        </CustomContainer>
    </ModalContainer >
}
const { width } = Dimensions.get('screen')

const styles = StyleSheet.create({
    title: {
        paddingTop: 0,
        paddingBottom: 12,
        fontSize: 24,
        color: cColors.MODAL_TITLE_TEXT_COLOR
    },
    container: {
        flex: 1,
        // width: width,
        backgroundColor: cColors.PAGE_BG_COLOR
    },
    containerMobile: {
        width: width
    },
    page: {
        width: Platform.OS === 'android' || Platform.OS === 'ios' ? width : '100%',
        flex: 1,
        paddingHorizontal: 0,
        display: 'flex',
        backgroundColor: cColors.PAGE_BG_COLOR
    },
    headerContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 14
    },
    categoryContainer: {
        flexDirection: 'row',
        marginBottom: 10,
        height: 70,
    },
    categoryName: {
        color: 'white',
        fontSize: 18
    },
    contextScrollStyle: {
        display: 'flex',
    }
})
