import React, { useEffect, useRef } from "react";
import { View, Text, StyleSheet, Dimensions, Platform, AppStateStatus, AppState, LayoutRectangle } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import cColors from "../styles/componentColors";
import BottomButton from "../components/BottomButton";
import IconBottomButton from "../components/IconBottomButton";
import ItemsFlatList from "../components/ItemsFlatList";
import ModalContainer from "../components/ModalContainer";
import { store } from "../redux";
import {
  clearRecentTasks,
  loadAllTasks,
  loadSaveForLaterTasks,
  loadSearchResultTasks,
  moveTasksToJustToday,
  resetSearchResultTask,
  removeDateFromSFLTask,
  loadLastDateTasks
} from "../services/tasksService";
import { warningToast } from "../utils/toast";
import { RootSiblingParent } from "react-native-root-siblings";
import { connect } from "react-redux";
import { MappedTasks } from "../types/Task";
import { getUserMetaData, setDailyImportDate } from "../services/userService";
import ContextBubblesTopList from "../components/ContextBubblesTopList";
import SearchBar from "../components/SearchBar";
import debounce from "lodash.debounce";
import { parseCurrentDateTimeIso } from "../utils/date";
import { delay } from "../utils/delay";

import CustomContainer from "../components/CustomContainer";


const { width } = Dimensions.get("window");

const OnEnterScreen = (props: {
  navigation: any;
  allTasks: { [key: string]: MappedTasks };
  route: any;
}) => {
  const [modalRect, setModalRect] = React.useState<LayoutRectangle>()

  const [yesterdayToTodayTasksIds, setYesterdayToTodayTaskIds] = React.useState<string[]>([]);
  const [saveForLaterToTodayTaskIdsRecent, setSaveForLaterToTodayTaskIdsRecent,] = React.useState<string[]>([]);
  const [saveForLaterToTodayTaskIds, setSaveForLaterToTodayTaskIds] = React.useState<string[]>([]);
  const yesterdayIncompleteTasks = Object.keys(props.allTasks["JustYesterdayIncomplete"])
    .filter((taskid) => !props.allTasks["JustYesterdayIncomplete"][taskid].checked);
  const [filterIndex, setFilterIndex] = React.useState<string>("contextless");
  const [clicked, setClicked] = React.useState(false);
  const [isSearchResults, setIsSearchResults] = React.useState(false);
  const [searchPhrase, setSearchPhrase] = React.useState<string>("");
  const [shouldUseAllOnNextSearch, setShouldUseAllOnNextSearch] = React.useState<boolean>(true);

  const appState = React.useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = React.useState(appState.current);

  const scrollRef: any = useRef();
  const page1Ref: any = useRef(null);
  const page2Ref: any = useRef();
  const page3Ref: any = useRef();

  // console.log(yesterdayToTodayTasksIds.length, saveForLaterToTodayTaskIdsRecent.length, saveForLaterToTodayTaskIds.length)

  const recentTasks =
    props.route.params && props.route.params.recentTasks
      ? props.route.params.recentTasks
      : null;
  const seperateYesterdayTaskIds = () => {
    const moveToJustTodayTaskIds = [];
    const moveToSaveForLaterTaskIds = [];

    for (let taskid of yesterdayIncompleteTasks) {
      if (yesterdayToTodayTasksIds.includes(taskid)) {
        moveToJustTodayTaskIds.push(taskid);
      } else {
        moveToSaveForLaterTaskIds.push(taskid);
      }
    }
    return [moveToJustTodayTaskIds, moveToSaveForLaterTaskIds];
  };
  const moveTasksToToday = async () => {
    const [toToday, toSaveForlater] = seperateYesterdayTaskIds();

    let mergedTaskIdsToJustToday = Array.from(
      new Set([
        ...toToday,
        ...saveForLaterToTodayTaskIdsRecent,
        ...saveForLaterToTodayTaskIds,
      ])
    );

    if (mergedTaskIdsToJustToday.length > 0) {
      await moveTasksToJustToday(mergedTaskIdsToJustToday);
    }

    if (toSaveForlater.length > 0) {
      await removeDateFromSFLTask(toSaveForlater);
    }
    // console.log("hi")
  };
  const showPage1 = yesterdayIncompleteTasks.length > 0;
  const showPage2 = Array.isArray(recentTasks) && recentTasks.length > 0;
  useEffect(() => {
    if (Platform.OS === "web") {
      if (scrollRef.current) {
        scrollRef.current.children[0].style.width = "100%";
      }
      if (page1Ref.current) {
        page1Ref.current.parentNode.style.width = "100%"
      }
      if (page2Ref.current) {
        page2Ref.current.parentNode.style.width = "100%"
      }
      if (page3Ref.current) {
        page3Ref.current.parentNode.style.width = "100%"
      }
    }

    const onReturnEvent = () => {
      getUserMetaData()
        .then((resp) => {
          const nowDate = parseCurrentDateTimeIso()
          if (resp) {
            if (resp.last_daily_import_date && resp.last_daily_import_date === nowDate) {
              delay(15)
                .then(() => {
                  props.navigation.goBack()
                })
            }
          } else {
            // console.log("resp is null")
          }
        })
        .catch(e => {

        })
    }

    const eventHandler = (nextAppState: AppStateStatus) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        onReturnEvent()
      }
      appState.current = nextAppState;
      setAppStateVisible(appState.current);
    }

    loadLastDateTasks().then(() => {
      if (isSearchResults) {
        loadSearchResultTasks({
          search_term: searchPhrase,
          context_id: filterIndex,
          task_type: "SAVE_FOR_LATER",
        });
      } else {
        loadSaveForLaterTasks();
        // loadSaveForLaterTasks();
      }
    });

    const subscription = AppState.addEventListener("change", eventHandler);

    return () => {
      subscription.remove()
    }
  }, [isSearchResults, filterIndex, scrollRef, showPage1, showPage2]);

  const done = () => {
    // console.log("done()")
    if (Platform.OS !== "web") warningToast("Moving...", "bottom");
    // console.log("after toast")

    moveTasksToToday()
      .then(() => {
        clearRecentTasks()
          .then(() => {
            setDailyImportDate()
              .then(() => {
                loadAllTasks()
                  .then(() => {
                    props.navigation.goBack();
                  })
                  .catch((e) => {
                    console.log("");
                  });
              })
              .catch((e) => { });
          })
          .catch((e) => {
            console.log("");
          });
      })
      .catch((e) => {
        console.log("");
      });
  };

  const handleSearchPhraseChange = (searchPhraseVal: string) => {
    // search term
    if (searchPhraseVal) {
      loadSearchResultTasks({
        search_term: searchPhraseVal,
        context_id: filterIndex,
        task_type: "SAVE_FOR_LATER",
      });
    }
  };

  const debouncedHandleSearchPhraseChange = React.useMemo(
    () => debounce(handleSearchPhraseChange, 300),
    [filterIndex]
  );

  let page_count = 1 + (showPage1 ? 1 : 0) + (showPage2 ? 1 : 0)


return (
  <ModalContainer
    navigation={props.navigation}
    screen="OnEnterScreen"
    onLayout={(rect) => { setModalRect(rect) }}>
    <CustomContainer>
      <RootSiblingParent>
        <ScrollView
          ref={scrollRef}
          style={styles.container}
          horizontal
          pagingEnabled
          contentContainerStyle={Platform.OS === 'android' || Platform.OS === 'ios' ? { width: (modalRect ? modalRect.width : width) * page_count } : { flex: 1 }}

        >
          {showPage1 && (
            <View ref={page1Ref} style={styles.page}>
              <View style={styles.headerContainer}>
                <Text style={styles.header}>
                  Move incomplete tasks to today
                </Text>
              </View>
              <ItemsFlatList
                listType="JustYesterdayIncomplete"
                navigation={props.navigation}
                extraSpaceAtEnd
                setTaskIds={(taskIds) => {
                  setYesterdayToTodayTaskIds(taskIds);
                }}
                paddingInCell
                hideArrowButton
              />
              <IconBottomButton
                side="right"
                singleTap={() => {
                  scrollRef.current.scrollTo({
                    x: (modalRect ? modalRect.width : width) * 1,
                    animation: true,
                  });
                }}
                text="➡️"
              />
            </View>
          )}
          {showPage2 && (
            <View
              style={styles.page}
              ref={page2Ref}>
              <View style={styles.headerContainer}>
                <Text style={styles.header}>Want to do these today?</Text>
              </View>
              <ItemsFlatList
                listType="SaveForLater"
                navigation={props.navigation}
                extraSpaceAtEnd
                setTaskIds={(taskIds) => {
                  setSaveForLaterToTodayTaskIdsRecent(taskIds);
                }}
                filter={
                  recentTasks
                    ? (task) => recentTasks.includes(task.id)
                    : undefined
                }
                paddingInCell
                hideArrowButton
              />

              <IconBottomButton
                side="right"
                singleTap={() => {
                  scrollRef.current.scrollTo({
                    x: (modalRect ? modalRect.width : width) * (showPage1 ? 2 : 1),
                    animation: true,
                  });
                }}
                text="➡️"
              />
            </View>
          )}
          <View style={styles.page} ref={page3Ref}>
            <View style={styles.headerContainer}>
              <Text style={styles.header}>Move saved tasks to today</Text>
              <SearchBar
                clicked={clicked}
                searchPhrase={searchPhrase}
                setSearchPhrase={(phrase) => {
                  setSearchPhrase(phrase);
                  if (phrase === "") {
                    resetSearchResultTask();
                  } else {
                    setIsSearchResults(clicked);
                  }
                  debouncedHandleSearchPhraseChange.cancel();
                  debouncedHandleSearchPhraseChange(phrase);
                }}
                setClicked={(clickedVal) => {
                  if (shouldUseAllOnNextSearch) {
                    setShouldUseAllOnNextSearch(false);
                    setFilterIndex("all");
                  }
                  setClicked(clickedVal);
                  setIsSearchResults(clickedVal);
                }}
                onCancel={() => {
                  setShouldUseAllOnNextSearch(true);
                }}
              />
            </View>
            <ContextBubblesTopList
              //@ts-ignore
              setContextIndex={(index: string) => {
                setFilterIndex(index);
                setShouldUseAllOnNextSearch(index === "all");
              }}
              navigation={props.navigation}
              contextIndex={filterIndex}
              marginSide="left"
            />
            <ItemsFlatList 
            hideArrowButton
              listType="SaveForLater"
              navigation={props.navigation}
              extraSpaceAtEnd
              filterIndex={filterIndex}
              setTaskIds={(taskIds) => {
                setSaveForLaterToTodayTaskIds(taskIds);
              }}
              isSearchResults={isSearchResults}
              paddingInCell
            />
            <IconBottomButton side="right" singleTap={done} text="🆗" />
          </View>
        </ScrollView>
      </RootSiblingParent>
    </CustomContainer>
  </ModalContainer>
);
};

const styles = StyleSheet.create({
  header: {
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 24,
    color: cColors.HEADER_TEXT_COLOR,
  },
  container: {
    backgroundColor: cColors.PAGE_BG_COLOR,
    flex: 1,
  },
  page: {
    backgroundColor: cColors.PAGE_BG_COLOR,
    flex: 1,
    width: Platform.OS === "web" ? "100%" : width,
    paddingTop: 0,
    // paddingHorizontal: 0,
  },
  manyContainer: {
    paddingHorizontal: 14,
    backgroundColor: cColors.PAGE_BG_COLOR,
    flex: 1,
    width,
    paddingTop: 0,
  },
  headerContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 14,
  },
});

const mapStateToProps = function (state: any) {
  return {
    allTasks: state.allTasks,
  };
};

export default connect(mapStateToProps)(OnEnterScreen);
